import React, { useRef } from 'react'
import { useState } from 'react';


const GridLayout = () => {

    const [imageSrc, setImageSrc] = useState("logo192.png");
    const parentRef = useRef(null); // Reference to the parent div
    const childRef = useRef(null); // Reference to the child div
    const imgRef= useRef(null);
    const [position, setPosition] = useState({ top: 0, left: 0 }); // State to store position


    

    function handleScroll(e){
                if (parentRef.current && childRef.current) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const childRect = childRef.current.getBoundingClientRect();
    
            // Calculate position relative to the parent
            setPosition({
              top: childRect.top,
              left: childRect.left,
              
            });

            if(position.top<200){
             
             imgRef.current.src="finance.png";
              

            } 

            if(position.top>200){

              imgRef.current.src="logo192.png";
            }

            if(position.top<0){
              imgRef.current.src="customerdata.jpg";
            }


          }
        
    }
     



    return (


        <>
           
            <div class="grid grid-cols-12 gap-4..." >
             <div class="col-span-6 relative bg-indigo-500..."
               
             >  
                <div className="h-96 overflow-auto bg-indigo-500..."    
                   
                   onScroll={(e) => handleScroll(e)} 
                   ref={parentRef}
                
                >

                    <div id="sidepanel" class="flex flex-col bg-indigo-500 ..."
                     ref={childRef}
                    >
                      <div class="basis-full">
                        

                        <h3 class="text-3xl ..."> 
                            
                            Effortless email & marketing automation Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer </h3>
                      </div>   
                      <div class="basis-full">
                        <h3 class="text-3xl ..."> Effortless email & marketing automation Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer </h3>
                      </div>  
                      <div class="basis-full">
                        <h3 class="text-3xl ..."> Effortless email & marketing automation Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer </h3>
                      </div>  
                      <div class="basis-full">
                        <h3 class="text-3xl ..."> Effortless email & marketing automation Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer </h3>
                      </div>  
                      <div class="basis-full">
                        <h3 class="text-3xl ..."> Effortless email & marketing automation Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer </h3>
                      </div>  
                      <div class="basis-full">
                        <h3 class="text-3xl ..."> Effortless email & marketing automation Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer </h3>
                      </div>

                      <div class="basis-full">
                        <h3 class="text-3xl ..."> Effortless email & marketing automation Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer Effortless email & marketing automation
                            That's the average time an ActiveCampaign customer </h3>
                      </div>

                </div>
                <div className="fixed bottom-4 left-4 bg-gray-800 text-white p-2 rounded">
            <p>Position:</p>
            <p>Top: {position.top}px</p>
            <p>Left: {position.left}px</p>
          
          </div>

            </div>
            </div>


                <div class="col-span-6 relative ...">
                    <div id="sidepanel" class="flex flex-row sticky absolute top-0 left-0 ..">
                        <div class="basis-full place-content-center ...">
                            <img
                                src={imageSrc} ref={imgRef}
                                alt="Dynamic Example"
                                className="w-96 h-96 object-cover rounded-lg shadow-md border border-gray-300 .."
                            />



                        </div>
                    </div>


                </div>
            </div>

        </>







    )
}

export default GridLayout