import {
    ArrowPathIcon,
    ChevronRightIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
  } from '@heroicons/react/20/solid'
  import { BoltIcon, CalendarDaysIcon, UsersIcon } from '@heroicons/react/24/outline'

const Footer = () => {


      const secondaryFeatures = [
        {
          name: 'Push to deploy.',
          description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
          icon: CloudArrowUpIcon,
        },
        {
          name: 'SSL certificates.',
          description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
          icon: LockClosedIcon,
        },
        {
          name: 'Simple queues.',
          description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
          icon: ArrowPathIcon,
        },
        {
          name: 'Advanced security.',
          description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
          icon: FingerPrintIcon,
        },
        {
          name: 'Powerful API.',
          description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
          icon: Cog6ToothIcon,
        },
        {
          name: 'Database backups.',
          description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. ',
          icon: ServerIcon,
        },
      ]
      const stats = [
        { id: 1, name: 'Developers on the platform', value: '8,000+' },
        { id: 2, name: 'Daily requests', value: '900m+' },
        { id: 3, name: 'Uptime guarantee', value: '99.9%' },
        { id: 4, name: 'Projects deployed', value: '12m' },
      ]
     
  return (
    <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
    <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base/7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
      {secondaryFeatures.map((feature) => (
        <div key={feature.name} className="relative pl-9">
          <dt className="inline font-semibold text-white">
            <feature.icon aria-hidden="true" className="absolute left-1 top-1 size-10 text-indigo-500" />
            {feature.name}
          </dt>{' '}
          <dd className="inline">{feature.description}</dd>
        </div>
      ))}
    </dl>
  </div>

  )
}

export default Footer