import logo from './logo.svg';
import Homepage from './components/Homepage';
import './App.css';
import Landingpage from './components/Landingpage';
import GridLayout from './components/GridLayout';
import FileNav from './components/FileNav';
import StatsData from './components/StatsData';
import Scrollcheck from './components/Scrollcheck';
import ScrollTest from './components/ScrollTest';
import Footer from './components/Footer';
import SampleLanding from './components/SampleLanding';




function App() {
  return (
    <div>
     <Homepage/>
     <Landingpage/>
     <GridLayout/>
     <StatsData/>
     <Footer/>
    
     
    
     
    
    </div>
  );
}

export default App;
